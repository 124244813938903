import {toAbsoluteUrl} from '../../_metronic/helpers'

export const languages = {
  ENGLISH: 'en',
  DEUTSCH: 'de',
  SPANISH: 'es',
  FRENCH: 'fr',
  JAPANESE: 'ja',
  CHINESE: 'zh',
  RUSSIAN: 'ru',
}

export const languagesInEnglish: Record<string, string> = {
  English: 'en',
  German: 'de',
  Spanish: 'es',
  French: 'fr',
  Japanese: 'ja',
  Chinese: 'zh',
  Russian: 'ru',
}
export const languageLabels: {[key: string]: string} = {
  // id: 'Bahasa Indonesia - Indonesian',
  // msa: 'Bahasa Melayu - Malay',
  // ca: 'Català - Catalan',
  // cs: 'Čeština - Czech',
  // da: 'Dansk - Danish',
  [languages.DEUTSCH]: 'Deutsch - German',
  [languages.ENGLISH]: 'English',
  // 'en-gb': 'English UK - British English',
  [languages.SPANISH]: 'Español - Spanish',
  // fil: 'Filipino',
  [languages.FRENCH]: 'Français - French',
  // ga: 'Gaeilge - Irish (beta)',
  // gl: 'Galego - Galician (beta)',
  // hr: 'Hrvatski - Croatian',
  // it: 'Italiano - Italian',
  // hu: 'Magyar - Hungarian',
  // nl: 'Nederlands - Dutch',
  // no: 'Norsk - Norwegian',
  // pl: 'Polski - Polish',
  // pt: 'Português - Portuguese',
  // ro: 'Română - Romanian',
  // sk: 'Slovenčina - Slovak',
  // fi: 'Suomi - Finnish',
  // sv: 'Svenska - Swedish',
  // vi: 'Tiếng Việt - Vietnamese',
  // tr: 'Türkçe - Turkish',
  // el: 'Ελληνικά - Greek',
  // bg: 'Български език - Bulgarian',
  [languages.RUSSIAN]: 'Русский - Russian',
  // sr: 'Српски - Serbian',
  // uk: 'Українська мова - Ukrainian',
  // he: 'עִבְרִית - Hebrew',
  // ur: 'اردو - Urdu (beta)',
  // ar: 'العربية - Arabic',
  // fa: 'فارسی - Persian',
  // mr: 'मराठी - Marathi',
  // hi: 'हिन्दी - Hindi',
  // bn: 'বাংলা - Bangla',
  // gu: 'ગુજરાતી - Gujarati',
  // ta: 'தமிழ் - Tamil',
  // kn: 'ಕನ್ನಡ - Kannada',
  // th: 'ภาษาไทย - Thai',
  // ko: '한국어 - Korean',
  [languages.JAPANESE]: '日本語 - Japanese',
  [languages.CHINESE]: '简体中文 - Simplified Chinese',
  // 'zh-tw': '繁體中文 - Traditional Chinese',
}

export const languageFlags = {
  [languages.ENGLISH]: toAbsoluteUrl('/media/flags/united-states.svg'),
  [languages.CHINESE]: toAbsoluteUrl('/media/flags/china.svg'),
  [languages.SPANISH]: toAbsoluteUrl('/media/flags/spain.svg'),
  [languages.SPANISH]: toAbsoluteUrl('/media/flags/japan.svg'),
  [languages.DEUTSCH]: toAbsoluteUrl('/media/flags/germany.svg'),
  [languages.FRENCH]: toAbsoluteUrl('/media/flags/france.svg'),
}

export default languages

const eventTypes = {
  PROGRESSING: 'Progressing',
  COMPLETED: 'Completed',
  IN_COMPLETED: 'In Completed',
  UPDATED: 'Updated',
  CREATED: 'Created',
  REMOVED: 'Removed',
  FAILURE: 'Failure',
  OTHERS: 'Others',
}

const eventTypeColors = {
  [eventTypes.PROGRESSING]: 'secondary',
  [eventTypes.COMPLETED]: 'secondary',
  [eventTypes.IN_COMPLETED]: 'secondary',

  [eventTypes.CREATED]: 'success',
  [eventTypes.UPDATED]: 'warning',
  [eventTypes.REMOVED]: 'info',
  [eventTypes.OTHERS]: 'primary',
  [eventTypes.FAILURE]: 'danger',
}

const eventCategories = {
  AUTH: 'Auth',
  PROFILE: 'Profile',

  GOAL: 'Goal',
  TO_DO: 'To Do',

  POINT_LEADER_REPORT: 'PointLeaderReport',

  ASSESSMENT: 'Assessment',
  ASSESSMENT_GROUP: 'Assessment Group',
  ASSESSMENT_FORM: 'Assessment Form',
  ASSESSMENT_FORM_BUILDER: 'Assessment Form Builder',
  ASSESSMENT_FORM_INSTRUCTION: 'Assessment Form Instruction',

  TAKING_ASSESSMENT: 'Taking Assessment',
  ASSIGN_ASSESSMENT: 'Assign Assessment',
  ASSIGN_INTERNAL_REPORT: 'Assign Internal Report',
  ASSIGN_COMPANY_CUSTOM_REPORT: 'Assign Company Custom Report',

  CUSTOM_REPORT: 'Custom Report',

  ASSIGN_TEAM_MEMBERS: 'Assign Team Members',

  EMPLOYEE_ASSESSMENT_RESULT: 'EmployeeAssessmentResult',
  COMPETENCY_CUTS: 'Competency cuts',

  DASHBOARD: 'Dashboard',
  DASHBOARD_ADMIN: 'Dashboard Admin',
  DASHBOARD_APPLICANT: 'Dashboard Applicant',
  DASHBOARD_EMPLOYEE: 'Dashboard Employee',
  DASHBOARD_HR_MANAGER: 'Dashboard Hr Manager',
  DASHBOARD_MANAGER: 'Dashboard Manager',
  DASHBOARD_TEAM_LEADER: 'Dashboard Team Leader',

  CLIENT: 'Client',
  CLIENT_SITE: 'Client Site',
  TEAM: 'Team',
  TEAM_LEADERS: 'Team Leaders',
  TEAM_MEMBERS: 'Team Members',
  SCHEDULE: 'Schedule',
  EMPLOYEE: 'Employee',

  COMPANY_NEWS: 'Company News',
  COMPANY_TREND: 'Company Trend',
  COMPANY_REPORT: 'Company Report',
  COMPANY_NOTIFICATION: 'Company Notification',
  COMPANY_EMPLOYEES: 'Company Employees',

  FAQ: 'Faq',
  CONTACT_US: 'Contact Us',

  APPLICATION: 'Application',
  QUESTION: 'Question',
  JOB: 'Job',
  MODULE: 'Module',
  POINT_LEADER_REPORTS: 'PointLeaderReports',
  REPORT_CONTENTS: 'ReportContents',
  INTERNAL_REPORT: 'InternalReport',
  USER: 'User',
  BASE_DATA: 'BaseData',
  OTHERS: 'Others',
  EVENT_LOG: 'Event Log',
}

export {eventTypes, eventCategories, eventTypeColors}

import axios from 'axios'
import apiRouterItems from '../constants/apiRouterItems'
import {eventCategories, eventTypes} from '../constants/eventConstants'
import {EventLogModel} from '../models/EventLogModel'

function useEventLog() {
  function addEventLog(event: Partial<EventLogModel>) {
    if (process.env.NODE_ENV !== 'production') return
    axios.post(apiRouterItems.EVENT_LOGS, {
      ...event,
      eventType: event.eventType || eventTypes.OTHERS,
      eventCategory: event.eventCategory || eventCategories.OTHERS,
    })
  }
  return {
    addEventLog,
  }
}

export default useEventLog

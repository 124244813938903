import defaultTopCompetencyFitScoreLevels from '../constants/defaultTopCompetencyFitScoreLevels'
import {ScoreLevelModel} from '../models/ScoreLevelModel'

function ProbableT(theta: number, a: number, b: number, c: number) {
  // probability of a correct response on item
  let pr = 0.0

  let dem = 1.0 + Math.exp(-1.7 * a * (theta - b))

  pr = c + (1.0 - c) / dem
  if (pr < 0.001) pr = 0.001

  if (pr > 0.999) pr = 0.999

  return pr
}

function calibrateTheta(arrParameters: any[]) {
  //theta will be returned
  let theta = 0.0

  //3PL
  let tdem = 0.0
  let tnum = 0.0
  let tho = 0.0
  let pr = 0.0

  // see - standard error - returned
  // th - theta in and updated
  // ii - number of administered items
  // obs - response (correct=1, wrong=0)
  //
  // formula is from Lord, p 61
  let nl = 0
  let seeth = 0.0
  do {
    tdem = 0.0
    tnum = 0.0
    tho = theta
    nl += 1
    //For Each objQuestion In arrQuestion
    for (const ap of arrParameters) {
      pr = ProbableT(theta, ap.aParameter, ap.bParameter, ap.cParameter)
      tnum =
        tnum +
        ((Math.round(ap.response) - pr) *
          1.7 *
          (ap.aParameter / (1.0 - ap.cParameter)) *
          (pr - ap.cParameter)) /
          pr
      tdem =
        tdem +
        1.7 *
          1.7 *
          ap.aParameter *
          ap.aParameter *
          ((1.0 - pr) / pr) *
          Math.pow((pr - ap.cParameter) / (1.0 - ap.cParameter), 2.0)
    }
    theta = theta + tnum / tdem
    if (theta > 3) {
      theta = 3
      tho = 3
    }
    if (theta < -3) {
      theta = -3
      tho = -3
    }
  } while (Math.abs(theta - tho) >= 0.00001 && nl <= 200)
  seeth = 1 / Math.sqrt(tdem)

  if (seeth > 99) seeth = 99

  return theta
}

function calcScoreLevelFromTopCompetencyFitScoreLevel(
  topCompetencyFitScoreLevels: ScoreLevelModel = defaultTopCompetencyFitScoreLevels
): ScoreLevelModel {
  return {
    high: topCompetencyFitScoreLevels?.high?.map((score) => score * 2) || [0, 0, 0],
    moderate: topCompetencyFitScoreLevels?.moderate?.map((score) => score * 1) || [0, 0, 0],
    low: topCompetencyFitScoreLevels?.low?.map((score) => score * 0) || [0, 0, 0],
  }
}

const scoringEngineHelpers = {
  ProbableT,
  calibrateTheta,
  calcScoreLevelFromTopCompetencyFitScoreLevel,
}

export default scoringEngineHelpers
